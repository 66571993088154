module.exports = [{
      plugin: require('/tmp/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-43277452-19"},
    },{
      plugin: require('/tmp/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
