import React from 'react';
import ClassPreview from ".";

const LocationPanel = props => {
  const {classes, placeholder} = props;
  return (
      <div className="section-content">
        <div className="row">
          {classes.sort((a,b) => 
              b < a).map(item => (
            <ClassPreview key={item.id} contentEntity={item} placeholder={placeholder} />
          ))}
        </div>
      </div>
  );
};

export default LocationPanel;