/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'gatsby';

import CMSWrapper from'@ic-hosting/ich-contentful-cms-wrapper';
//import CMSWrapper from'../../local-modules/@ic-hosting/ich-contentful-cms-wrapper/src';

const renderComponent = ({ contentEntity, placeholder }) => (
  <div className="col-sm-6 col-md-4 mb-30">
    <div className="class-items bg-white border-1px p-20">
      <CMSWrapper {...contentEntity}>
        <div className="thumb">
          <img className="img-fullwidth" src={
            contentEntity.image ? contentEntity.image.size({
              width: 317,
              height: 317
            })
            : placeholder ? placeholder.size({
              width: 317,
              height: 317,
            }) : "http://placehold.it/317x317"
          } alt={contentEntity.image ? contentEntity.image.description : "no image"} />
          <div className="viewtime">
            <Link className="btn btn-theme-colored btn-flat mt-10" to={`class/${contentEntity.slug}`}> View Details</Link>
          </div>
        </div>
        <h3 className="">
        <Link to={`class/${contentEntity.slug}`}>{contentEntity.title}</Link></h3>
        <div className="class-date"><i className="fa fa-clock-o"></i> <em>{contentEntity.day} {contentEntity.time}</em></div>
        <div className="class-date mb-10"><i className="fa fa-map-marker"></i> <em dangerouslySetInnerHTML={{__html: contentEntity.location && contentEntity.location.address}} /></div>
      </CMSWrapper>
    </div>
  </div>
);

export default renderComponent;